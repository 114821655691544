<template>
  <div id="chat-app">
    <div class="header">
      <van-nav-bar
          title=""
          left-text=""
      />
    </div>
    <div class="msg-box">
      <template v-for="(m, index) in messages" :key="index">
        <div class="d-c-c" v-if="m.showTime">
          <span class="time">{{ m.create_times }}</span>
        </div>
        <div class="msg-item">
          <template v-if="m.uid===this.uid">
            <div class="d-s-s-r">
              <div>
                <img class="user-logo" src="@/assets/img/user.png">
              </div>
              <div class="d-n-s msg-right">
                <div>
                  <!--              <span class="time">{{ m.create_times }}</span>-->
                </div>
                <span v-if="m.del==1" class="del">{{ m.msg }}</span>
                <template v-else>
                  <span v-if="m.type=='msg'" class="text">{{ m.msg }}</span>
                  <img v-else class="text-pic" :src="this.site_url+m.msg" @load="scrollBottom"  @click="showPic(this.site_url+m.msg)">
                </template>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="d-s-s">
              <div>
                <img class="user-logo" src="@/assets/img/user.png">
              </div>
              <div class="d-n-s msg-right">
                <div>
                  <span class="nickname">{{ m.username }}</span>
                  <!--              <span class="time">{{ m.create_times }}</span>-->
                </div>
                <span v-if="m.del==1" class="del">{{ m.msg }}</span>
                <template v-else>
                  <span class="text">{{ m.msg }}</span>
                </template>
              </div>
            </div>
          </template>

        </div>
      </template>
    </div>

    <div class="footer d-n-c">
      <div class="d-b-c" v-if="showDown">
        <img src="@/assets/img/down.png" class="down" @click="scrollBottom">
      </div>
      <div class="d-b-e write">
        <textarea class="input" rows="1" v-model="inputMessage" ref="myTextarea" @input="adjustTextareaHeight"></textarea>
        <img v-if="!showEmoji" src="@/assets/img/emoji.png" class="emoji_btn p1" @click="openEmoji">
        <img v-if="showEmoji" src="@/assets/img/word.png" class="emoji_btn p2" @click="closeEmoji">
        <van-button class="send_btn" size="small" type="primary" @click="sendMessage">发送</van-button>
      </div>
    </div>
    <div>
      <EmojiPicker
          v-if="showEmoji"
          :native="true"
          :disable-skin-tones="true"
          :hide-search="true"
          :hide-group-icons="false"
          :hide-group-names="false"
          :display-recent="true"
          :pickerType="textarea"
          :mode="insert"
          @select="onSelectEmoji"/>
    </div>

  </div>
</template>

<script>
import 'vue3-emoji-picker/css'
import EmojiPicker from 'vue3-emoji-picker';

export default {
  components: {
    EmojiPicker,
  },
  data() {
    return {
      lastScrollTop: 0, // 用于保存上一次滚动的位置
      uid: 0,
      token: "",
      inputMessage: '',
      messages: [],
      socket: null,
      msg: "",
      lastInsertTime: 0,
      showEmoji: false,
      showDown: false,
      site_url:process.env.VUE_APP_UPLOAD_URL,
    };
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    this.token = queryParams.get("t");
    this.uid = Number(queryParams.get("u"));
    this.conn()
    this.scrollBottom()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    adjustTextareaHeight() {
      const textarea = this.$refs.myTextarea;
      textarea.style.height = "auto"; // 先将高度设置为自动以重新计算
      const h = textarea.scrollHeight < 31 ? 31 : textarea.scrollHeight;
      textarea.style.height = h + "px";
    },
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop === undefined) {
        return
      }
      const totalHeight = document.documentElement.scrollHeight;
      // 可视窗口高度
      const windowHeight = window.innerHeight;

      // 判断是否滚动到底部
      // console.log(scrollTop, totalHeight, windowHeight,scrollTop+windowHeight)
      this.showDown = scrollTop !== totalHeight - windowHeight;
      if (scrollTop > this.lastScrollTop) {
        // 向下滚动
      } else {
        // 向上滚动
        if (this.showEmoji) {
          this.closeEmoji()
        }
      }

      this.lastScrollTop = scrollTop;
    },
    openEmoji() {
      this.showEmoji = true
      const footer = document.querySelector('.footer');
      // 修改 bottom 样式为 320px
      footer.style.bottom = '330px';
      this.scrollBottom()
    },
    closeEmoji() {
      this.showEmoji = false
      const footer = document.querySelector('.footer');
      // 修改 bottom 样式为 320px
      footer.style.bottom = '0';
      this.scrollBottom()
    },
    onSelectEmoji(emoji) {
      const textarea = this.$refs.myTextarea;
      const cursorPos = textarea.selectionStart;
      const textBefore = this.inputMessage.substring(0, cursorPos);
      const textAfter = this.inputMessage.substring(cursorPos);
      const insertedText = emoji.i;

      this.inputMessage = textBefore + insertedText + textAfter;

      // 将光标移动到插入的字符串之后
      const newCursorPos = cursorPos + insertedText.length;
      this.$nextTick(() => {
        textarea.setSelectionRange(newCursorPos, newCursorPos);
        textarea.focus();
      });
    },
    conn() {
      // 连接 WebSocket 服务器
      this.socket = new WebSocket(process.env.VUE_APP_WS_URL);

      // 监听消息
      this.socket.addEventListener('open', () => {
        //初始化用户信息
        let data = {
          type: 'init',
          token: this.token,
        }
        // 发送消息到服务器
        this.socket.send(JSON.stringify(data));
      });
      this.socket.addEventListener('message', (e) => {
        let message = JSON.parse(e.data);
        switch (message.type) {
          case 'init':
            this.uid = message.uid
            break;
          case 'msg':
            this.messages.push(message);
            this.scrollBottom()
            break;
        }
      });
    },
    scrollBottom() {
      this.$nextTick(() => {
        const middleContent = document.documentElement || document.body;
        // console.log(middleContent.scrollHeight)
        middleContent.scrollTop = middleContent.scrollHeight;
      })
    },
    sendMessage() {
      if (this.inputMessage.trim() !== '') {
        let data = {
          type: 'msg',
          token: this.token,
          msg: this.inputMessage
        }
        // 发送消息到服务器
        this.socket.send(JSON.stringify(data));
        this.inputMessage = '';
        this.$refs.myTextarea.style.height = "30px"
        this.closeEmoji()
      }
    },
    onClickLeft() {
      window.history.go(-1)
    },
  },
};
</script>

<style scoped lang="scss">
#chat-app {
  margin: 0;
  padding: 0;
  overflow: hidden
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #3498db;
  color: #fff;
}

.footer {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  color: #fff;

  .write {
    background:#e8ebef;
    width: 100%
  }

  .input {
    height: 31px;
    padding: 5px;
    flex: 1;
    color: black;
    margin: 10px;
  }

  .down {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    line-height: 30px; /* 使内容垂直居中 */
    text-align: center; /* 使内容水平居中 */
    background-color: #ffffff; /* 设置背景颜色 */
    border-radius: 50%; /* 使元素呈现为圆形 */
  }

  .emoji_btn {
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-bottom: 5px;
  }
  .p1 , .p2 {
    margin-bottom: 8px;
  }

  .send_btn {
    margin: 5px;
    margin-bottom: 15px;
  }
}

.msg-box {
  min-height: 70vh;
  margin-top: 40px;
  margin-bottom: 100px; /* 保持与 .bottom 的距离 */
  overflow-y: auto; /* 允许垂直滚动 */
  .time {
    margin-top: 20px;
    font-size: 12px;
  }
}

.user-logo {
  width: 30px;
  height: 30px;
}

.msg-item {
  margin-top: 10px;
}

.msg-right {
  margin-left: 10px;

  .nickname {
    font-size: 10px;
  }

  .del {
    font-size: 14px;
    color: #969799;
  }

  .text {
    font-size: 18px;
    white-space: pre-line; /* 保留换行符，允许自动换行 */
    overflow: visible; /* 允许内容溢出显示在容器外部 */
    word-break: break-all; /* 强制在单词内换行 */
  }
  .text-pic {
    max-height: 200px;
    max-width: 80%;
  }
}

</style>
