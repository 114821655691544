<template>
  <van-pull-refresh @refresh="onRefresh">
  <ChatRoom msg="Welcome to Your Vue.js App"/>
  </van-pull-refresh>
</template>

<script>
import ChatRoom from './components/ChatRoom.vue'

export default {
  name: 'App',
  components: {
    ChatRoom
  },
  methods: {
    onRefresh() {
      window.location.reload();
    }
  }
}
</script>

<style>

</style>
